import React, { useState } from "react";

const Contact = ({ data }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  if (data) {
    var contactName = data.name;
    // var street = data.address.street;
    //var city = data.address.city;
    // var state = data.address.state;
    // var zip = data.address.zip;
    var phone = data.phone;
    var contactEmail = data.email;
    var contactMessage = data.contactmessage;
  }

  const submitForm = () => {
    const emailUrl = `https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=${contactEmail}&su=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(message)}&ui=2&tf=1&pli=1`;

    // const emailUrl2 = `mailto:?subject=${encodeURIComponent(
    //   subject
    // )}&body=${encodeURIComponent(name)} (${encodeURIComponent(email)}`;

    window.open(emailUrl);

    // window.open(
    //   `mailto:${contactEmail}?subject=${encodeURIComponent(
    //     subject
    //   )}&body=${encodeURIComponent(name)} (${encodeURIComponent(
    //     email
    //   )}): ${encodeURIComponent(message)}`
    // );
  };

  return (
    <section id='contact'>
      <div className='row section-head'>
        {/* <div className='two columns header-col'>
          <h1>
            <span>Get In Touch.</span>
          </h1>
        </div> */}

        <div className='ten columns'>
          {/* <p className='lead'>{contactMessage}</p> */}
        </div>
      </div>

      <div className='row'>
        {/* <div className='eight columns'>
          <form onSubmit={submitForm}>
            <fieldset>
              <div>
                <label htmlFor='contactName'>
                  Name <span className='required'>*</span>
                </label>
                <input
                  type='text'
                  value={name}
                  size='35'
                  id='contactName'
                  name='contactName'
                  onChange={(e) => setName(e.target.value)}
                />
              </div>

              <div>
                <label htmlFor='contactEmail'>
                  Email <span className='required'>*</span>
                </label>
                <input
                  type='text'
                  value={email}
                  size='35'
                  id='contactEmail'
                  name='contactEmail'
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div>
                <label htmlFor='contactSubject'>Subject</label>
                <input
                  type='text'
                  value={subject}
                  size='35'
                  id='contactSubject'
                  name='contactSubject'
                  onChange={(e) => setSubject(e.target.value)}
                />
              </div>

              <div>
                <label htmlFor='contactMessage'>
                  Message <span className='required'>*</span>
                </label>
                <textarea
                  cols='50'
                  rows='15'
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  id='contactMessage'
                  name='contactMessage'
                ></textarea>
              </div>

              <div>
                <button onClick={submitForm} type='submit' className='submit'>
                  Submit
                </button>
              </div>
            </fieldset>
          </form>

          <div id='message-warning'> Error boy</div>
          <div id='message-success'>
            <i className='fa fa-check'></i>Your message was sent, thank you!
            <br />
          </div>
        </div> */}

        <div className='text-center'>
          <p className='lead'>{contactMessage}</p>
        </div>

        <aside className='twelve columns text-center footer-widgets'>
          <div className='widget widget_contact'>
            <h4>My Contacts</h4>
            <p className='address'>
              {phone} <br />
              {contactEmail}
              <br />
              <a
                href={`https://wa.me/${phone}`}
                target='_blank'
                rel='noopener noreferrer'
              >
                Text me on whatsapp
              </a>
            </p>
          </div>
        </aside>
      </div>
    </section>
  );
};

export default Contact;
