const Techstacks = ({ data }) => {
  return (
    <section id="techstack">
      <div>
        <div>
          <h1>The Techstacks & Frameworks I use.</h1>

          <div className="tech-stack">
            {data?.frameworks?.map((tech) => (
              <div key={tech.image} className="tech">
                <img alt={''} src={'images/portfolio/' + tech.image} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Techstacks;
