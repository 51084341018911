import React from "react";

function Pricing({ data }) {
  const iconer = (name) => {
    switch (name) {
      case "Static Website":
        return <i class='fa fa-globe' aria-hidden='true'></i>;
      case "Small Business":
        return <i class='fa fa-briefcase' aria-hidden='true'></i>;
      case "Ecommerce":
        return <i class='fa fa-shopping-cart' aria-hidden='true'></i>;

      default:
        return <i class='fa fa-rocket' aria-hidden='true'></i>;
    }
  };

  return (
    <section id='pricing'>
      <div className='pricing-info'>
        <h5 className='text-center'>
          Web Pricing
          {/* Domain registration and hosting is subject to expexted visitot traffic */}
        </h5>
      </div>
      <div className='price-stack'>
        {data?.categories?.map((categ) => (
          <div className='price-card' key={categ.name}>
            <div className='card-price text-center'>
              <div className='title'>
                {/* <i class="fa fa-briefcase" aria-hidden="true"></i> */}
                {iconer(categ.name)}
                <h2>{categ.name}</h2>
              </div>
              <div className='price'>
                <h5>
                  {/* <sup>$</sup> */}
                  {categ.cost}
                </h5>
              </div>
              <div className='option text-left'>
                <ul>
                  {categ?.features?.map((fet, ind) => (
                    <li key={ind}>
                      {" "}
                      <i className='fa fa-check' aria-hidden='true'></i>
                      <small>{fet}</small>
                    </li>
                  ))}
                </ul>
              </div>
              {/* <a href="#">Order Now </a> */}
            </div>
          </div>
        ))}
      </div>
      <div className='pricing-info'>
        <p className='text-center'>
          All websites come with good and modern looking design, responsiveness
          &amp; interactive forms. <br />
          Sms / Email integration costs are subject to quantity
          {/* Domain registration and hosting is subject to expexted visitot traffic */}
        </p>
      </div>
    </section>
  );
}

export default Pricing;
