import React from "react";

const ItemWrap = (props) => {
  const projectImage = "images/portfolio/" + props?.image;

  return (
    <div key={props.title} className='item-wrap'>
      <a
        target='_blank'
        rel='noopener noreferrer'
        href={props.url}
        title={props.title}
      >
        <img alt={props.title} src={projectImage} />
        <div className='overlay'>
          <div className='portfolio-item-meta'>
            <h5>{props.title}</h5>
            <p>{props.desc}</p>
          </div>
        </div>
        <div className='link-icon'>
          <i className='fa fa-link'></i>
        </div>
      </a>
    </div>
  );
};

const Portfolio = ({ data }) => {
  return (
    <section id='portfolio'>
      <div className='row'>
        <div className='twelve columns collapsed'>
          <h1>Click on thumbnail to see some of my top Builds.</h1>

          <div
            id='portfolio-wrapper'
            className='bgrid-thirds s-bgrid-thirds cf'
          >
            <div className='columns portfolio-item'>
              {data &&
                data.projects.slice(0, 3).map(function (project) {
                  return <ItemWrap key={project.title} {...project} />;
                })}
            </div>

            <div className='columns portfolio-item'>
              {data &&
                data.projects.slice(3, 6).map(function (project) {
                  return <ItemWrap key={project.title} {...project} />;
                })}
            </div>

            <div className='columns portfolio-item'>
              {data &&
                data.projects
                  .slice(6, data.projects.length)
                  .map(function (project) {
                    return <ItemWrap key={project.title} {...project} />;
                  })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
